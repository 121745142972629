import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const ThankYouPage = () => {
  return (
    <Layout pageTitle="Thank You">
      <Container>
        <Row>
          <Col>
            <div className="hero-banner large-hero light-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/wessuc_default_hero.png"
                alt=""
                aspectRatio={3 / 1}
                layout="fullWidth" />
              <div className="hero-text">
                <div className="section">
                  <h1>Here's your checklist!</h1>
                  <h3>
                    Get ready to refine your production processes and save money on wastewater disposal, storage, transportation and treatement.
                  </h3>
                  <a href="http://wessuc.com/wp-content/uploads/2019/08/Wessuc_Wastewater_Audit_Checklist.pdf">
                    <Button variant="ternery" className="shadow">DOWNLOAD YOUR CHECKLIST</Button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <MustRead />
    </Layout>
  )
}

export default ThankYouPage